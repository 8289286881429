@import '../styles/global';

.formContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  @include for-phone-only {
    overflow: scroll;
    // padding-bottom: 48px;
  }
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: blur(20px);
  z-index: -1;
  opacity: 0.5; // Adjust opacity for better readability
}

.postform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  margin: 0 auto;
  width: 100%;
}

.ctaGroup {
  margin: 0 auto;
  width: inherit;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inputGroup {
  margin: auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
}

@mixin inputLabel {
  color: $black-700;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  line-height: 24px; /* 150% */
  letter-spacing: -0.64px;
}

.inputLabel {
  @include inputLabel();
}

.inputLabelCta {
  @include inputLabel();
  margin-left: 24px;
  font-weight: 600;
}

.urlInput {
  border-radius: 56px;
  border: 1px solid $black-700;
  background: $yellow-300;
  box-shadow: 0px 0px 16px 0px rgba(245, 158, 11, 0.5);
  height: 64px;
  padding: 24px 24px;
  color: $black-700;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.72px;
  width: 240px;
  transition: all 0.25s ease-in-out;
  outline: none;
  @include for-phone-only {
    width: calc(100vw - 112px);
  }
  &:hover {
    background: darken($yellow-300, 3%);
  }
  &::placeholder {
    color: $black-700;
  }
  &:focus-visible,
  &:active,
  &:focus,
  &:focus-within,
  &:target {
    outline-offset: 1px;
    outline-color: $yellow-100;
    background-color: $yellow-300;
    box-shadow: 0px 0px 40px 32px rgba(252, 211, 77, 0.3);
    width: 560px;
    @include for-phone-only {
      width: calc(100vw - 64px);
    }
  }
}

.fetchedContainer {
  height: 100%;
  width: 100%;

  & input {
    transition: background 0.25s ease-in-out;
    border-radius: 4px;
    border: 1px solid $black-700;
    &:focus,
    &:focus-visible,
    &:target {
      outline-offset: 1px;
      outline: 1px solid $black-300;
      background: $black-050;
    }
  }
  @include for-phone-only {
    width: 100%;

    border: none;
  }
}

.fetchedContainerHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0px;

  @include for-phone-only {
    padding: 8px 0px;
    border-bottom: none;
  }
}

.fetchedContainerTitle {
  color: $black-700;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 150% */
  letter-spacing: -0.64px;
}

.fetchedInfo {
  padding: 8px 16px;
  color: $black-700;
  background-color: $yellow-100;
  border: none;
  display: flex;
  flex-direction: column;
}
.fetchedURL {
  font-size: 13px;
  line-height: 16px;
  font-family: monospace;
  color: $black-700;
  &:hover {
    text-decoration: underline;
  }
}

.fetchedName {
  font-family: Inter;
  font-size: 16px;
  line-height: 24px; /* 150% */
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.64px;
}

.fetchedContainerBody {
  width: inherit;
  display: flex;
  flex-direction: row;

  @include md {
    flex-direction: column;
    overflow-y: scroll;
  }
}

.fetchedContainerLeft {
  max-width: 400px;
  min-width: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-right: 1px solid $black-700;
  height: calc(100dvh - 32px);
  max-height: calc(100dvh - 32px);
  background-color: $yellow-300;
  @include md {
    width: 100%;
    max-width: 100%;
    border-right: none;
    height: auto;
    padding-bottom: 32px;
  }
}

.fetchedContainerRight {
  width: calc(100% - 320px);
  max-width: auto;
  width: 100%;
  padding: 48px 0 0 0;
  float: right;
  background-color: $black-050;
  @include md {
    width: 100%;
    padding: 0;
  }
}

.loadingContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fetchedContainerRightInner {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @include xl {
    max-width: 100%;
  }
  @include lg {
    max-width: 100%;
  }
  @include for-phone-only {
    padding-top: 16px;
  }
}

.textBox {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  height: 100%;
}

.textBoxInner {
  min-height: 96%;
  height: calc(100dvh - 222px);
  resize: none;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px 16px 16px;
  border: none;
  color: $black-700;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.64px;
  background: transparent;
  &::placeholder {
    color: $black-400;
  }
  &:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
  }
}

.songCluster {
  display: flex;
  flex-direction: column;
  gap: 0px;
  border: 1px solid $black-700;
  border-radius: 8px;
  overflow: hidden;
  background-color: $yellow-300;
  @include for-phone-only {
    flex-direction: column;
  }
}

.songInfoCluster {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.songInput {
  display: flex;
  width: 100%;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  background: $yellow-100;
  border: none;
  color: $black-700;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 150% */
  letter-spacing: -0.64px;
}

.thumbnail {
  height: auto;
  min-width: 144px;
  background-size: cover;
  background-position: center;
  aspect-ratio: 16 / 9;
  background-color: $yellow-300;
}

.clearButton {
  float: right;
  width: auto;
  height: 24px;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  border: none;
  background-color: transparent;
  color: $black-700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.submitCluster {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  border-top: 1px solid $black-700;
  padding: 16px;
}

.submitCta {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 56px;
  background: $yellow-300;
  color: $black-700;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
  letter-spacing: -0.64px;
  border: 1px solid $black-700;
  cursor: pointer;
  transition: background 0.25s ease-in-out;
  width: 100%;
  &:hover,
  &:active {
    background: darken($yellow-300, 10%);
  }
}

.remainingChars {
  font-size: 13px;
  line-height: 24px;
  color: $black-500;
  text-align: right;
  z-index: 20;
}

.descriptionLabel {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.64px;
}

.descriptionCluster {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.postingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80dvh;
  width: 80dvw;
}
