@import '../styles/global';

.commentButton {
  display: flex;
  color: $black-700;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 184.615% */
  letter-spacing: -0.52px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 8px;
  z-index: 1;
  transition: background 0.2s ease-in-out;
  &:hover {
    background: $black-200;
  }
}
