.slick-slider {
  height: 100%;
}
.slick-list {
  height: 100%;
}
.slick-slide {
  height: 100%;
  min-height: 100%;
}
.slick-track {
  height: 100%;
}
