@import '../styles/global';
.notificationsPage {
  background-color: $black-050;
}
.pageTitle {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 160% */
  letter-spacing: -0.8px;
  margin: 0;
}

.header {
  color: $black-700;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 16px;
  height: 56px;
  border-bottom: 1px solid $black-700;
  position: sticky;
  top: 0;
  background-color: $black-100;
  z-index: 7;
}

.markAsReadButton {
  display: flex;
  flex-direction: row;
  gap: 8px;
  border: none;
  padding: 0 16px;
  background-color: transparent;
  color: $black-700;
  cursor: pointer;
  align-items: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
  letter-spacing: -0.52px;
  height: 100%;
  border-left: 1px solid $black-700;
  &:hover {
    background-color: $black-200;
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-inline-start: 0px;
  padding: 16px;
  gap: 8px;
}
.link {
  width: 100%;
  background-color: $black-950;
}

.notifContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  padding: 16px;
  align-items: flex-start;
  gap: 16px;
  border: 1px solid $black-700;
  transition: background-color 0.2s ease;
  border-radius: 8px;
  box-shadow: 2px 2px 0 0 $black-700;
  cursor: pointer;
  &:hover,
  &:focus,
  &:focus-visible {
    background-color: rgba(0, 0, 0, 0.03);
  }
}

.unread {
  background-color: $yellow-100;
  .notifCopy {
    font-weight: 600;
  }
  &:hover {
    background-color: darken($yellow-100, 5%);
  }
}

.avatar {
  width: 24px;
  height: 24px;
  border-radius: 56px;
  background: $black-300;
  border: 1px solid $black-700;
}

.notifBody {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.notifMain {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.notifCopy {
  color: $black-700;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 120% */
  letter-spacing: -0.8px;
  margin-bottom: 2px;
  margin-top: 0;
}

.notifTime {
  color: $black-600;
  font-size: 0.8em;
  margin-top: 4px;
}

.songMetadataContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding: 0 16px 0 0;
  border: 1px solid $black-700;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 8px;
}

.songMetadata {
  font-size: 0.9em;
  color: $black-700;
  margin: 0;
  font-weight: 500;
  transition: color 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0;
  height: 72px;
}
.songArtist {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: $black-700;
  margin: 0;
}

.songTitle {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin: 0;
  line-clamp: 2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.songInfo {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0;

  width: 100%;
}

.thumbnailWrapper {
  margin: 0;
  width: 72px;
  height: 72px;
  min-width: 72px;
  min-height: 72px;
  overflow: hidden;
}

.songThumbnail {
  width: 72px;
  height: 72px;
  object-fit: cover;
  margin-right: 8px;
}

.contentWrapper {
  flex: 1;
  min-width: 0;
}

.commentText {
  margin: 8px 0;
  font-style: italic;
  color: $black-700;
}

.emptyState {
  height: calc(100vh - 56px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.notificationAlert {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 16px;
  gap: 16px;
  border-bottom: 1px solid $black-700;
  background-color: $yellow-300;
}

.notificationAlertText {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
  color: $black-700;
}

.avatarTooltip {
  position: absolute;
  bottom: 100%;
  left: -5%;
  transform: translateX(-50%) translateY(0);
  padding: 4px 8px;
  background-color: $black-700;
  color: white;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  z-index: 10;
  margin-bottom: 4px;
  font-weight: 500;
  pointer-events: none;
  max-width: 200px; // Add max-width to prevent very long names from stretching too far
  text-overflow: ellipsis;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 10%;
    margin-left: 0px;
    border-width: 4px;
    border-style: solid;
    border-color: $black-700 transparent transparent transparent;
  }
}

.avatarGroup {
  display: flex;
  align-items: center;
  min-height: 24px; // Add min-height to prevent layout shift

  .avatarWrapper {
    position: relative;
    display: inline-block;

    &:hover {
      z-index: 6 !important;
      .avatarTooltip {
        opacity: 1;
        visibility: visible;
        transform: translateY(-4px);
      }
    }
  }
}

.groupAvatar {
  width: 24px;
  height: 24px;
  border-radius: 56px;
  border: 1px solid $black-700;
  background: $black-300;
  position: relative;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
  }
}

// Update existing styles
.notifContainer {
  &.grouped {
    background-color: $black-100;

    &:hover {
      background-color: $black-200;
    }

    .avatarGroup {
      opacity: 0.9;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.typeIcon {
  width: 24px;
  height: 24px;
  background-color: $black-700;
}

.typeIconContainer {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px; // Adjust as needed for spacing
}

.replyText {
  font-style: italic;
  color: $black-700; // Adjust color as needed
  margin-top: 4px;
}
