@import '../styles/global';

.authButton {
  height: 200px;
  width: 40px;
  border-radius: 24px;
  border: 1px solid $black-700;
  margin: 8px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-right: 1px solid $black-700;
  background-color: $yellow-300;
  z-index: 2000;
  @include md {
    height: 100%;
    padding: 8px;
    border-radius: 56px;
    width: 100%;
    z-index: 2000;
  }
  cursor: pointer;
  &:hover {
    background-color: $yellow-400;
    border-right: 1px solid $black-700;
  }
}

.ninetyDegrees {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  transform: rotate(90deg);
  white-space: nowrap; /* Prevents the text from wrapping */
  @include md {
    transform: none;
  }
}
