@import '../styles/global';

.songContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  margin-bottom: 8px;
  border: 1px solid $black-700;
  border-radius: 8px;
  box-shadow: 2px 2px 0 0 $black-700;
  overflow: hidden;
  align-items: center;
  & p {
    margin: 0;
  }
  &:hover {
    background-color: $yellow-300;
  }
}

.thumbnail {
  width: auto;
  height: 80px;
  aspect-ratio: 1 / 1;
  background-position: center;
  background-size: cover;
  border-right: 1px solid $black-700;
}

.songName {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  color: $black-700;
  text-decoration: none;
}

.songArtist {
  font-size: 14px;
  line-height: 16px;
  color: $black-700;
}
