$black-050: #fafafa;
$black-100: #f5f5f5;
$black-200: #e5e5e5;
$black-300: #d4d4d4;
$black-400: #a3a3a3;
$black-500: #404040;
$black-600: #525252;
$black-700: #404040;
$black-800: #262626;
$black-900: #18181b;
$black-950: #0a0a0a;

$yellow-50: #fffbeb;
$yellow-100: #fef3c7;
$yellow-200: #fde68a;
$yellow-300: #fcd34d;
$yellow-400: #fbbf24;
$yellow-500: #f59e0b;
$yellow-600: #d97706;
$yellow-700: #b45309;
$yellow-800: #92400e;
$yellow-900: #78350f;
$yellow-950: #451a03;

$green-50: #f0fdf4;
$green-100: #dcfce7;
$green-200: #bbf7d0;
$green-300: #86efac;
$green-400: #4ade80;
$green-500: #22c55e;
$green-600: #16a34a;
$green-700: #15803d;
$green-800: #166534;
$green-900: #14532d;
$green-900: #052e16;
