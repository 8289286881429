@import '../styles/global';

.container {
  background-color: $black-050;
  @include md {
  }
}

.header {
  height: 56px;
  max-height: 56px;
  min-height: 56px;
  border-bottom: 1px solid $black-700;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0;
  position: sticky;
  top: 0;
  background-color: $black-100;
  z-index: 10;
  @include md {
  }
}

.dayTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: -0.64px;
}

.songCount {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
  letter-spacing: -0.52px;
}

.emptyContainer {
  border-bottom: 1px solid $black-700;
  min-height: 256px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emptyCopy {
  padding: 16px;
}

.songList {
  padding: 16px;
  border-bottom: 1px solid $black-700;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
