@import '../styles/global';

.container {
  width: 100%;
  height: 274px;
  background-color: $yellow-300;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid $black-700;

  @include md {
    height: auto;
  }
}

.body {
  padding: 32px 0 16px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  & > p {
    color: $black-700;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    letter-spacing: -0.64px;
    margin: 0 0 8px 8px;
  }
}

.recentPosts {
  color: $black-700;
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: -0.64px;
  margin: 8px 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
}

.bob {
  display: inline-block;
  animation: bob 2s infinite ease-in-out;
  z-index: 0;
}

@keyframes bob {
  0%,
  100% {
    transform: translateY(4px);
  }
  50% {
    transform: translateY(-4px);
  }
}

.header {
  height: 56px;
  border-bottom: 1px solid $black-700;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: $yellow-300;
  .paragraph {
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    margin: 0px;
  }
}
.strong {
  font-weight: 600;
  line-height: 20px;
  max-height: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.left {
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
  height: 100%;
  margin-left: 16px;

  @include md {
    margin-left: 0;
  }
}

.recordIcon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 16px;
  width: 56px;
  display: none;
  border-right: 1px solid $black-700;
  background-color: $yellow-400;

  @include md {
    display: flex;
  }
}

.stacked {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0;
  height: 100%;
  margin-left: 0;

  @include md {
    margin-left: 16px;
  }
}

.stackedRight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 16px;
  height: 100%;
  width: auto;
  max-width: auto;
  border-left: 1px solid $black-700;
  background-color: $yellow-300;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease-in-out;

  &:hover {
    background-color: darken($yellow-300, 10%);

    .strong {
      display: flex;
      align-items: center;

      svg {
        transform: translateX(24px) rotate(45deg); // Move and rotate the icon
        transition: transform 0.3s ease-in-out; // Ensure smooth transition
      }
    }
  }
}

.strong svg {
  transition: transform 0.3s ease-in-out; // Ensure transition is applied to default state
}

#external-link {
  transition: transform 0.3s ease-in-out; // Ensure transition is applied
}

.logo {
  margin-left: 8px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.title {
  font-size: 32px;
  line-height: 32px;
  font-weight: 500;
  margin: 0;
  color: $black-700;
  margin: 0;
  letter-spacing: -2px;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  padding: 0px;
  border-radius: 8px;
  max-width: 98%;
  max-height: 98%;
  overflow-y: auto;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 12px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: $black-700;
  z-index: 1000;
  background-color: $yellow-300;
  padding: 8px;

  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
