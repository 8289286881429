@import '../styles/global';

.footer {
  width: 100%;
  min-height: 50vh;
  padding: 32px 16px;
  background-color: $black-800;
  color: $yellow-100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 1px solid $black-700;
  @include for-phone-only {
    padding-bottom: 80px;
    height: 80dvh;
    min-height: 80dvh;
  }
}

.footerHead {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.footerBody {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  @include for-phone-only {
    flex-direction: column;
    margin-bottom: 40px;
  }
}

.footerColumn {
  flex: 1;
  margin: 0px;
  text-align: left;
  h4 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
    color: $yellow-300;
    font-weight: 500;
  }
  .footerText {
    font-size: 14px;
    line-height: 20px;
    margin: 4px 0;
    color: $black-300;
    font-weight: 400;
  }
  a {
    color: $yellow-300;
    text-decoration: none;
    font-weight: 400;
    &:hover {
      text-decoration: underline;
    }
  }
  &:last-child {
    text-align: right;
    @include for-phone-only {
      text-align: left;
    }
  }
}

.footerBottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  font-size: 14px;
  line-height: 20px;
  color: $black-300;
  @include for-phone-only {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  a {
    color: $yellow-300;
    text-decoration: none;
    font-weight: 400;
    margin-right: 16px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.footerBottomLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.footerBottomRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  @include for-phone-only {
    justify-content: flex-start;
    margin-top: 16px;
  }
}

.socialLink {
  margin-left: 16px;
  width: 20px;
  height: 20px;
  svg {
    width: 20px;
    height: 20px;
    margin: auto;
  }
}
