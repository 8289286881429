@import '../styles/global';

.likesArray {
  padding: 0;

  .sortButton {
    padding: 8px 16px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: background-color 0.1s ease;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    &:hover {
      background-color: $black-100;
    }

    svg {
      transition: transform 0.3s ease;
    }

    &.rotated {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .songsGrid {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .noSongs {
    text-align: center;
    padding: 2rem;
    color: #666;
  }
}

.loading {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.loadingText {
  color: $black-700;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.headContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 0 16px 0;
}

.headText {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}
