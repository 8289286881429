@import '../../styles/global';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $black-700;
  color: $black-300;
  z-index: 10000;
  border-radius: 8px;
}

.header {
  display: flex;
  padding: 16px;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid $black-800;
}

.body {
  padding: 16px;
}

.footer {
  padding: 0px 16px 16px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.title {
  color: $black-300;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.64px;
  margin: 0;
}

.confButton {
  background: transparent;
  color: $black-300;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.64px;
  padding: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  &:hover {
    background: $black-800;
  }
}
