@import '../../styles/global';

.overflowMenu {
  position: relative;
  display: block;
}

// Define a mixin for the button styles
@mixin menuButtonStyles {
  color: $black-700;
  border: none;
  background: none;
  width: 100%;
  padding: 8px;
  text-align: left;
  cursor: pointer;

  transition: background 0.025s ease-in-out;
  &:hover {
    background: $black-200;
  }
}

// Define a mixin for the common menu styles
@mixin menuStyles {
  color: $black-700;
  position: absolute;
  background-color: $black-050;
  border: 1px solid $black-700;

  padding: 8px 0;
  min-width: 160px;
  width: max-content;
  box-shadow: 2px 2px 0 0 $black-700;
  z-index: 100;
  & button {
    font-size: 16px;
    line-height: 24px;
  }
}

.overflowMenu .menu {
  @include menuStyles;
  right: 0;
}

.overflowMenu .menu button {
  @include menuButtonStyles;
}

.overflowButton {
  background: none;
  padding: 4px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  align-items: center;
  z-index: 1;
  font-size: 16px;
  line-height: 24px;

  transition: background 0.025s ease-in-out;
  &:hover {
    background: $black-200;
  }
}

.menuUpwards {
  @include menuStyles;
  bottom: 100%;
  transform: translateX(8%);
}
// Applying the same styles to buttons in .menuUpwards
.menuUpwards button {
  @include menuButtonStyles;
}
