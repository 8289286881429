@import '../styles/global';

.container {
  max-width: 640px;
  margin: 0 auto;
  width: 100%;
  background-color: $black-100;
  border: 1px solid $black-700;
  border-radius: 8px;
  transition: background-color 0.025s ease-in-out;
  // box-shadow: 2px 2px 0px 0px #404040;
  @include md {
    max-width: 100%;
  }
  @include sm {
    max-width: 100%;
  }
  @include for-phone-only {
    max-width: 100%;
  }
  &:hover {
    background-color: darken($color: $black-100, $amount: 3);
    & .thumbnailImage {
      opacity: 1;
    }
  }
}
.selected {
  background-color: $black-600;
  border: 1px solid $black-700;
  transition: background-color 0.025s ease-in-out;
  color: $black-700;

  box-shadow:
    0px 0px 15px -3px rgba(20, 83, 45, 0.5),
    0px 0px 6px -2px #22c55e;

  & .songInfo {
    color: $black-700;
  }

  @include md {
  }
  @include for-phone-only {
  }
  &:hover {
    background-color: darken($color: $black-600, $amount: 1);
    & .thumbnailImage {
      opacity: 1;
    }
  }
}

.left {
  @include md {
  }
  @include for-phone-only {
  }
}

.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $black-700;
  padding: 8px 8px 8px 16px;
}

.userLink {
  width: fit-content;
}

.leftTop {
  @include md {
  }
}
.bottomStuff {
  width: 100%;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.songCluster {
  cursor: pointer;
  margin: 16px 16px 0 16px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid $black-300;
  transition: background-color 0.1s ease-in-out;
  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    background-color: $yellow-300;
    border-color: $black-700;
    & .thumbnailImage {
      mix-blend-mode: multiply;
    }
  }
}

.songInfo {
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 0;
  text-decoration: none;
  color: $black-700;
}

.songName {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 150% */
  letter-spacing: -0.64px;
}

.songArtist {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 150% */
  letter-spacing: -0.64px;
  @include for-phone-only {
  }
}

.postedByCluster {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
}

.songPostedBy {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 150% */
  letter-spacing: -0.64px;
}

.songCommentBox {
  padding: 32px 20px;
  border-bottom: 1px solid $black-700;
  .descriptionBoxLabel {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 123.077% */
    letter-spacing: -0.52px;
  }
}

.songComment {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.commentText {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.72px;
  margin: 4px 0;
  word-break: break-word;
  & > a {
    background-color: lighten($yellow-300, $amount: 10);
    text-decoration: underline;
    &:hover {
      background-color: lighten($yellow-300, $amount: 0);
    }
  }
}

.songPostedDate {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.48px;
  margin-top: 3px;
}

.thumbnailImage {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  background-position: center;
  background-size: contain;
}

.avatar {
  height: 40px;
  width: 40px;
  border-radius: 48px;
  border: 1px solid $black-700;

  @include md {
  }
}
.userLink {
  text-decoration: none;
}
.songPostedInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: fit-content;
}

.utilButtons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
  width: 100%;
}
