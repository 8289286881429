@import '../styles/global';

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: rgba($yellow-300, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modalContent {
  background-color: $black-100;
  border-radius: 8px;
  width: calc(100dvw - 32px);
  height: calc(100dvh - 32px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  border: 1px solid $black-700;
  @include for-phone-only {
    width: 100%;
    height: 100dvh;
    border-radius: 0;
    max-height: 100dvh;
  }
}

.modalHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;

  color: $black-050;
}

.modalTitle {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.closeButton {
  position: absolute;
  top: 32px;
  right: 32px;
  background: none;
  border: none;
  color: $black-050;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 24px;
  z-index: 100;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: rgba($black-700, 0.2);
  }
  @include for-phone-only {
    top: 16px;
    right: 16px;
  }
}

.modalBody {
  height: 100%;
  overflow-y: hidden;
  @include for-phone-only {
    overflow-y: auto;
  }
}
