@import '../styles/global';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: inherit;
  z-index: 10000;
}

.group {
  @include md {
    width: 100%;
  }
}

.title {
  color: $black-700;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 160% */
  letter-spacing: -0.8px;
  width: 100%;
  padding: 16px;
  margin: 0;
  border-bottom: 1px solid $black-800;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid $black-800;
  gap: 0px;
  box-shadow: 2px 2px 0 0 $black-700;
  & img {
    aspect-ratio: 16 / 9;
    height: 240px;
    border-radius: 8px;
    border: 1px solid $black-800;
    margin-bottom: 8px;
  }
}

.songInfo {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.songInfoInner {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.songName {
  color: $black-700;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.96px;
}

.artist {
  color: $black-700;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.64px;
}

.deleteButton {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 40px;
  border: none;
  background: $black-050;
  color: var(--red-600, #dc2626);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 100% */
  letter-spacing: -0.64px;
  border: 1px solid #dc2626;
  cursor: pointer;
  &:hover {
    background: var(--red-600, #dc2626);
    color: $black-050;
  }
}

.loadingContainer {
  width: 100%;
  height: 100%;
  @include md {
    // padding-bottom: 32px;
    overflow-y: scroll;
  }
}

.postingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 100dvw;
}

.postingText {
  margin: 0;
  font-size: 2rem;
  font-weight: 700;
  line-height: 24px;
  color: $black-700;
  text-align: center;
  margin: auto;
}

.titleWithBack {
  display: flex;
  align-items: center;
}

.backButton {
  background: none;
  border: none;
  color: $black-700;
  font-size: 18px;
  cursor: pointer;
  margin-right: 8px;
}
