@import '../styles/global';

.container {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  background: $black-100;
  transition: all 0.025s ease-in-out;
  border-top: 1px solid $black-700;
  &:hover {
    background: darken($black-100, 3%);
  }
}

.avatar {
  aspect-ratio: 1 / 1;
  width: 40px;
  min-width: 40px;
  border: 1px solid $black-700;
  border-radius: 56px;
}

.textareaWrapper {
  width: 100%;
}

.commentForm {
  overflow-y: hidden; // Hide vertical scrollbar
  resize: none; // Disable textarea resizing by user
  margin-top: -2px;
  background-color: transparent;
  border: none;
  color: $green-900;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.64px;
  width: 100%;
  transition: opacity 0.3s ease;
  resize: none;
  height: 40px;
  margin-top: 12px;
  flex-grow: 1;
  &:focus-visible {
    outline: none;
    background: transparent;
  }
}
.buttonContainer {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  height: auto;
}

.postButton {
  border: none;
  padding: 0;
  cursor: pointer;
  height: fit-content;
  display: flex;
  align-items: center;
}

.submitting {
  .commentForm {
    opacity: 0.6;
  }
}

.overlay {
  position: fixed;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba($color: $yellow-300, $alpha: 0.5);
  cursor: not-allowed;
}

.spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $yellow-300;
  padding: 4px 16px;
  width: 100%;
  border-radius: 40px;
}
