@import '../styles/global';

a {
  width: 100%;
}

.listItem {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  background-color: transparent;
  transition: all 0.025s ease-in-out;
  border: 1px solid $black-700;
  box-shadow: 2px 2px 0 0 $black-700;
  overflow: hidden;
  &:hover {
    background-color: $yellow-300;
  }
}

.avatar {
  width: 80px;
  height: 80px;
  background-position: center;
  background-size: cover;
  background-color: $black-800;
  border-right: 1px solid $black-700;
}

.displayName {
  color: $black-700;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.64px;
  margin: 0;
}
