// Spinner.module.scss

@import '../styles/global';

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
}

.spinner {
  border: 2px solid $yellow-300;
  border-top: 2px solid $black-700;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
