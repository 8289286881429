@import '../styles/global';

.comments {
  margin: 40px 0 0 0;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -1.2px;
}
.commentList {
  width: 100%;
}

.container {
  border-radius: 8px;
  border: 1px solid $black-700;
  overflow: hidden;
  background-color: $black-100;
  box-shadow: 2px 2px 0px 0px #404040;
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  margin-top: 16px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 56px;
  border: 1px solid $black-700;
  object-fit: cover;
}

.infoStacked {
  display: flex;
  flex-direction: column;
  gap: 0px;
  justify-content: center;
  align-items: flex-start;
  width: auto;
}
.userName {
  color: $black-700;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 184.615% */
  letter-spacing: -0.52px;
}

.postTime {
  color: $black-700;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 184.615% */
  letter-spacing: -0.52px;
}

.mainComment {
  padding: 16px;
  width: 100%;
}

.userCluster {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
}

.commentBody {
  width: 100%;
  color: $black-700;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.64px;
  margin-top: 8px;
  p {
    margin: 0;
  }
  a {
    color: $black-500;
  }
}

.commentText {
  display: block;
  width: 100%;
}

.deleteButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.userLink {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: auto;
}
.replies {
  padding-left: 16px;
  width: 100%;
  margin-bottom: 16px;
}

.reply {
  width: 100%;
  border-left: 1px solid $black-700;
  border-bottom: 1px solid $black-700;
  padding: 16px;
  &:last-of-type {
    border-radius: 0 0 0 8px;
  }
}

.replyBody {
  width: 100%;
  color: $black-700;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.64px;
  p {
    margin: 0;
  }
  a {
    color: $black-500;
  }
}

.leaveReplyButton {
  background-color: $yellow-300;
  color: $black-700;
  border: 1px solid $black-700;
  padding: 4px 12px;
  border-radius: 40px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  transition: background-color 0.3s ease;
  margin: 0 0 16px 16px;

  &:hover {
    background-color: darken($yellow-300, 10%);
  }
}

.leaveReplyButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 24px 0 16px 0;
  border-top: 1px solid $black-700;
}

.leaveReplyButton {
  &.loading {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
