@import '../styles/global';

.tosContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  background-color: $black-100;
  color: $black-700;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}

.title {
  color: $black-700;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 40px;
}

.effectiveDate,
.effectiveDate,
.intro,
.sectionContent,
.note {
  line-height: 24px;
  font-size: 16px;
  text-align: left;
}

.sectionTitle {
  color: $black-700;
  font-weight: 500;
  margin-bottom: 8px;
}

.tosList {
  margin-bottom: 16px;
  padding-left: 24px;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
}

.listItem {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  margin-left: 24px;
}

.divider {
  border: none;
  border-top: 1px solid $black-700;
  margin: 24px 0;
}
