@import '../styles/global';
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 640px;
  margin: 0 auto;
  color: $black-400;
  height: 100%;

  p {
    font-size: 16px;
    line-height: 24px;
    color: $black-700;
  }

  h5 {
    color: $yellow-400;
    margin-bottom: 8px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }

  h3 {
    margin-top: 16px;
  }

  h1 {
    margin-top: 0;
    color: $black-050;
  }

  hr {
    width: 20%;
    border: 0.5px solid $black-800;
  }

  a {
    color: $black-700;
    background-color: none;
    text-decoration: underline;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: $yellow-200;
    }
  }
}
