@import '../styles/global';

.cookieNotification {
  background-color: $black-100;
  color: $black-700;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  border-bottom: 1px solid $black-700;
}

.cookieNotificationContent {
  display: flex;
  flex-direction: column;
  gap: 0px;
  & > p {
    margin: 0;
    padding: 0;
  }
}

.cookieHeader {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.64px;
  margin: 0;
  padding: 0;
}

.acceptButton {
  background-color: $yellow-300;
  color: $black-700;
  border: none;
  padding: 8px 16px;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  border: 1px solid $black-700;

  &:hover {
    background-color: $yellow-200;
  }
}
