@import '../styles/global';

.title {
  margin-bottom: 16px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 640px;
  padding: 16px;

  border-radius: 8px;
  gap: 32px;
  margin: 0 auto 0 auto;

  @include md {
    width: 100%;
    height: auto;
    border: none;
    padding: 24px 0;
    height: 100vh;
  }
  @include for-phone-only {
    height: auto;
  }

  .profileBio {
    resize: vertical;
    min-height: 256px;
    padding: 12px 16px;
    border-radius: 8px;
    background: $black-050;
    border: 1px solid $black-700;
    color: $black-700;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.64px;
    &:active,
    &:focus {
      background: $black-200;
      border: 1px solid $black-200;
      outline: none;
    }
  }
}

.top {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

input[type='file'] {
  color: rgba(0, 0, 0, 0);
}

.editLabel {
  display: flex;
  flex-direction: column;
  color: $black-300;
  font-size: 16px;
  line-height: 24px;
}

.avatarPreview {
  width: 256px;
  max-width: 256px;
  height: auto;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  border: 1px solid $black-700;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-color: $yellow-300;
  background-blend-mode: normal;
  transition: background-blend-mode 0.25s ease-in-out;
  @include for-phone-only {
    width: calc(100vw - 32px);
    height: auto;
  }

  &:hover {
    background-blend-mode: multiply;
  }
  &::-webkit-file-upload-button {
    display: none;
  }
}

.formLabel {
  padding-bottom: 4px;
  color: $black-500;
  font-size: 13px;
  line-height: 16px;
  width: auto;
}

.avatarLabel {
  position: relative;
  cursor: pointer;
  width: auto;
  display: contents;
}

.fileInput {
  display: none;
}

.input {
  display: flex;
  width: 100%;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: $black-050;
  border: 1px solid $black-700;
  color: $black-700;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.64px;
  &:active,
  &:focus {
    background: $black-200;
    border: 1px solid $black-200;
    outline: none;
  }
}

.cta {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background-color: $yellow-300;
  border-radius: 40px;
  color: $black-700;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.72px;
  border: 1px solid $black-700;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  &:hover {
    background: $yellow-400;
    color: $black-700;
  }
}
