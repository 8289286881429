@import '../styles/global';

.container {
  background-color: $black-050;
  height: 100vh;
  overflow-y: auto;
  position: relative;
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  @include for-phone-only {
    margin-bottom: 56px;
  }
}

.songList {
  @include md {
  }
}

.loadMoreContainer {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-height: 111px;

  @include md {
    margin-bottom: 56px;
  }
}

.loadMoreButton {
  width: 100%;
  color: $black-700;
  background-color: $black-100;
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  padding: 24px;
  cursor: pointer;
  border-radius: 56px;
  border: 1px solid $black-700;
  &:hover {
    background-color: $black-700;
    color: $yellow-300;
  }
}

.infoBox {
  display: none;
  @include md {
  }
}

.infoBoxInner {
  @include for-phone-only {
  }
}

.backToTopWrapper {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 32px;
  display: flex;
  justify-content: center;
  pointer-events: none;
  z-index: 1000;
  @include for-phone-only {
    padding: 80px 8px;
    position: fixed;
  }
}

.hideBackToTop {
  display: none;
}

.backToTopButton {
  z-index: 1001;
  padding: 8px 16px;
  background-color: $black-700;
  color: $black-050;
  border: 1px solid $black-700;
  border-radius: 64px;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  gap: 8px;
  align-items: center;
  transition:
    background-color 0.3s ease-in-out,
    opacity 0.3s,
    visibility 0.3s;
  pointer-events: auto;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.5);

  @include for-phone-only {
    // Adjust these styles as needed for mobile
  }
}

.backToTopButton:hover {
  background-color: $black-800;
  color: $black-050;
}

.showBackToTop {
  opacity: 1;
  visibility: visible;
}

@keyframes slideUpAndFadeOut {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background-color: $yellow-200;
  padding: 24px;
  margin: 16px;
  border-radius: 8px;
  animation: slideUpAndFadeOut 3s cubic-bezier(0.22, 0.61, 0.36, 1) forwards;
}
.loading-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin: 0;
  text-align: center;
  letter-spacing: -0.64px;
  color: $black-700;
}
