@import '../styles/global.scss';

.toolbar {
  width: 56px;
  height: 100vh;

  background-color: $black-100;
  border-right: 1px solid $black-700;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  z-index: 10;
}

.top {
  border-bottom: 1px solid $black-700;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 274px;
}

.postCTA {
  height: 176px;
  width: 40px;
  border-radius: 24px;
  border: 1px solid $black-700;
  margin: 8px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-right: 1px solid $black-700;
  background-color: $yellow-300;

  &:hover {
    background-color: $yellow-400;
    border-right: 1px solid $black-700;
  }
}
.postedCTA {
  height: 56px;
  width: 56px;
  border-top: 1px solid $black-700;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-right: 1px solid $black-700;
  background-color: $black-100;

  &:hover {
    background-color: $black-200;
    border-right: 1px solid $black-700;
  }
}

.ninetyDegrees {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  transform: rotate(90deg);
  white-space: nowrap; /* Prevents the text from wrapping */
}

.toolbarLink {
  height: 56px;
  width: 56px;
  border-top: 1px solid $black-700;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid $black-700;
  &:hover {
    background-color: $black-200;
    border-right: 1px solid $black-700;
  }
}
.toolbarLinkTop {
  height: 56px;
  width: 56px;
  max-height: 56px;
  min-height: 56px;
  border-bottom: 1px solid $black-700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loggedOutContainer {
}

.sidePanelContainer {
}

.topright {
}

.logo {
}

.avatar {
  height: 24px;
  width: 24px;
  border-radius: 24px;
}
.ctaContainer {
}

.postingNote {
}

.postedCTA {
  &:hover {
  }
}

.sidePanelHeader {
}

.toolbarLinks {
}

.navLink {
  &:hover {
  }
}

.toolbarAvatar {
  height: 24px;
  width: 24px;
  border-radius: 24px;
  border: 1px solid $black-700;
}

.catchPhrase {
}

.bottomCluster {
}

.footer {
  button {
  }
}

.userNugget {
  button {
  }
  .mainUserInfo {
  }
  .mainUserName {
  }
}
