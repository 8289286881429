@import "../styles/global";

.container {
  width: 100%;
  height: 100vh;
  background-color: $black-200;
  @media (max-width: 900px) {
    .hide-on-mobile {
      display: none;
    }
  }
}

.topBar {
  width: 100%;
  height: 56px;
  border-bottom: 1px solid $black-400;
}

.hide-on-mobile {
  display: block;

  @media (max-width: 900px) {
    display: none;
  }
}
