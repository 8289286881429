@import '../styles/global';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: $black-200;
  position: relative;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.loaded {
  opacity: 1;
}

.loading {
  opacity: 0;
}

.toolbar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 56px;
  background-color: $black-100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  transition: top 0.9s ease-in-out;
}

.open {
  top: 0; /* Position at the top of the container */
  bottom: auto; /* Override bottom to prevent conflicts */
  border-bottom: 1px solid $black-700;
}

.closed {
  bottom: 0; /* Position at the bottom of the container */
  top: auto; /* Override top to prevent conflicts */
  border-top: 1px solid $black-700;
}

.toolbarLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
}

.toolbarRight {
  display: flex;
  flex-direction: row;
  gap: 0;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  cursor: pointer;
  border-left: 1px solid $black-700;
}

.refreshButton,
.closeButton,
.openButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 100%;
  gap: 0;
  transition: background-color 0.2s ease-in-out;
  border-right: 1px solid $black-700;

  &:hover {
    background-color: $black-200;
  }
}

.closeButton,
.openButton {
  border-right: none;
}

.openButton,
.refreshButton {
  background-color: $yellow-300;
  &:hover {
    background-color: darken($yellow-300, 10%);
  }
}

.spinning {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loadingBar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: $yellow-300;
  animation: loading 2s infinite;
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.loadingState {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  color: $black-300;
  font-size: 12vw;
  line-height: 12vw;
  font-weight: bold;
  word-wrap: break-word;
  text-align: center;
}

.toolbarTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  line-height: 24px;
  color: $black-700;
}

.toolbarTitleText {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.toolbarSubtitle {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.canvasContainer {
  flex: 1;
  width: 100%;
  overflow: hidden;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.marquee {
  display: flex;
  height: 100%;
  min-height: 100%;
}

.marqueeSecondary {
  display: flex;
  width: 200%;
  animation: scroll-horizontal 40s linear infinite;
  animation-delay: 20s; /* Half of the primary duration for offset */
}

@keyframes scroll-horizontal {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* Move by half the width */
  }
}

.songList {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
  gap: 8px;
  padding: 72px 16px 16px 16px;
  transition:
    opacity 0.3s,
    transform 0.3s;
}

.songChip {
  width: 100%;
  height: 96px;
  max-height: 96px;
  min-height: 96px;
  padding: 8px 24px 8px 0;
  background-color: $black-100;
  border: 1px solid $black-700;
  border-radius: 96px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  overflow: hidden;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1.5s forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.songChip:hover {
  background-color: $yellow-300;
  & > .thumbnail {
    mix-blend-mode: multiply;
    transition: mix-blend-mode 0.2s ease-in-out;
  }
}

.thumbnail {
  width: 96px;
  height: 96px;

  border-radius: 96px;
  margin-right: 16px;
  object-fit: cover;
  border-top: 1px solid $black-700;
  border-right: 1px solid $black-700;
  border-bottom: 1px solid $black-700;
  border-left: none;
  mix-blend-mode: normal;
  transition: mix-blend-mode 0.2s ease-in-out;
}

.songDetails {
  display: flex;
  flex-direction: column;
}

.songName,
.songArtist {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.artist {
  font-size: 14px;
  font-weight: normal;
}

.userDetails {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.userThumbnail {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
  object-fit: cover;
  background-color: $black-700;
}

.userName {
  font-size: 14px;
  color: $black-700;
}

.fadeIn {
  opacity: 1;
  transform: translateY(0);
}

.fadeOut {
  opacity: 0;
  transform: translateY(10px);
}

.landingPage {
  height: 100dvh;
  max-height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 0;
  color: $black-700;
  overflow-y: auto;
  background-color: $yellow-200;
}

.landingPage h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.landingPage p {
  font-size: 18px;
  margin-bottom: 40px;
}

.features {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.feature {
  background-color: $black-100;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.feature h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.feature p {
  font-size: 16px;
}

.getStartedButton {
  margin-top: 40px;
  padding: 10px 20px;
  font-size: 18px;
  background-color: $yellow-300;
  color: $black-700;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: darken($yellow-300, 10%);
  }
}

.landingPageContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%; /* Ensure it takes the full height of the parent */
  background-color: $yellow-300;
  overflow-y: auto;
  overflow-x: hidden;
}
.headerBar {
  width: 100%;
  height: 56px;
  min-height: 56px;
  background-color: $yellow-200;
  border-bottom: 1px solid $black-700;
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  @include for-phone-only {
    display: none;
  }
}
.headerBarContent {
  margin: auto 0;
  & > p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: $black-700;
    margin: 0;
  }
}

.landingPageHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 218px; /* Fixed height */
  max-height: 218px;
  min-height: 218px;
  width: 100%;
  border-bottom: 1px solid $black-700;
  padding: 0;
  text-align: left;
  background-color: $yellow-200;
  & > .headerText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 16px 0 16px;
    & > p {
      margin: 0 0 24px 0;
      font-size: 22px;
      line-height: 28px;
      font-weight: 500;
      width: 70%;
      color: $black-700;

      @include xl {
        width: 60%;
        font-size: 24px;
      }

      @include lg {
        width: 65%;
        font-size: 22px;
      }

      @include md {
        width: 70%;
        font-size: 20px;
      }

      @include sm {
        width: 85%;
        font-size: 18px;
      }

      @include for-phone-only {
        width: 90%;
        font-size: 16px;
      }

      &:last-child {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-style: italic;
      }
    }
  }
  @include for-phone-only {
    height: auto; /* Fixed height */
    max-height: 80dvh;
    min-height: 50dvh;
  }
}

.landingPageBody {
  width: 100%;
  height: 100%;
  background-color: $yellow-200;

  position: relative;
  padding: 16px 0;
}

.songCluster {
  flex: 0 0 auto;
  width: auto;
  margin: 0 10px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid $black-300;
  transition: background-color 0.1s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  &:hover {
    background-color: $yellow-300;
    border-color: $black-700;
  }
}

.sliderContainer {
  width: 100%;
  height: fit-content;
  padding: 16px 0 40px 0;
  border-bottom: 1px solid $black-700;
}

.slick-slider {
  height: 100%;
  overflow: hidden;
}

.thumbnailImage {
  width: auto;
  height: 100%;
  aspect-ratio: 1/1;
  background-position: center;
  background-size: cover;
  border: 1px solid $black-700;
  margin-left: 16px;
  opacity: 0.75;
  border-radius: 8px;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}

.songInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  margin-left: 16px;
  margin-top: 8px;
  & > .songName,
  .artist {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 16px);
    text-align: left;
  }
}

.marquee:hover,
.marqueeSecondary:hover {
  animation-play-state: paused;
}

.avatarContainer {
  border: 1px solid $black-700;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  overflow: hidden;
  margin: 8px;
  & > .userThumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.howItWorksContainer {
  width: 100%;
  height: fit-content;
  background-color: $yellow-100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
}
.howItWorks {
  width: 100%;
  height: fit-content;
  background-color: $yellow-200;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: 1px solid $black-700;
  overflow-y: auto;
  border-radius: 8px;

  .howItWorksTitle {
    font-size: 16px;
    line-height: 24px;
    margin: 40px 0 40px 16px;
    background-color: $yellow-200;
    z-index: 1;
    color: $black-700;
    font-weight: 600;
  }

  .steps {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    gap: 0px;
    width: 100%;
  }

  .step {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 8px 16px;
    background-color: $yellow-200;
    width: 100%;
    border-top: 1px solid $black-700;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: darken($yellow-200, 5%);

      .stepTitle {
        h3 {
          margin-left: 4px;
        }

        svg {
          animation: spinSlow 2s linear infinite;
        }
      }
    }

    .stepTitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    h3 {
      font-size: 16px;
      line-height: 24px;

      font-weight: 500;
      color: $black-700;
      transition:
        margin-left 0.3s ease-in-out,
        font-weight 0.2s ease-in-out;
    }

    & > p {
      font-size: 16px;
      line-height: 24px;
      color: $black-700;
      text-align: left;
      width: 50%;
      margin-bottom: 16px;
      & > a {
        color: $black-700;

        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        text-decoration: none;
        width: fit-content;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@keyframes spinSlow {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
