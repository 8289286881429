@import '../styles/global';

.loadingContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loadingText {
  color: $black-700;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.userPageHeader {
  height: 56px;
  margin: 0 auto;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid $black-700;
  position: sticky;
  top: 0px;
  background-color: $black-100;
  z-index: 20;
}

.userPageHeaderInner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}

.sidePanelHeadUserCluster {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  .mainAvatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f0f0f0;
    background-size: cover;
    background-position: center;
    border: 1px solid $black-700;
  }
}

.sidePanelHeaderButtons {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 8px;
}

.headerButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

@mixin sidePanelHeaderButton {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 24px;
  color: $black-700;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 123.077% */
  letter-spacing: -0.52px;
  border: none;
  background-color: transparent;

  cursor: pointer;
  &:hover {
    background-color: $black-200;
  }
}

.followButton {
  @include sidePanelHeaderButton();
  padding: 8px 16px;
  border: 1px solid $black-700;
}
.unfollowButton {
  @include sidePanelHeaderButton();
  padding: 8px 16px;
  border: none;
}

.sidePanelHeaderButton {
  @include sidePanelHeaderButton();
}

.sidePanelHeaderButtonYellow {
  @include sidePanelHeaderButton();
  background: $yellow-300;
  color: $black-700;
  &:hover {
    background-color: $yellow-400;
  }
}

.mainUserInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  .mainUserName {
    color: $black-700;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 160% */
    letter-spacing: -0.8px;
    margin: 0;
  }
  .mainSubheader {
    color: $black-700;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 123.077% */
    letter-spacing: -0.52px;
    margin: 0;
  }
}
.sidePanelSection {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-top: 1px solid $black-800;
  width: 100%;
  .sidePanelSectionTitle {
    color: $black-500;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.56px;
    margin: 0;
  }
}

.bodyPanel {
  background-color: $black-050;
  padding-bottom: 64px;
  width: 100%;
  padding: 16px 16px 64px 16px;
  min-height: calc(100vh - 106px);
  overflow-y: scroll;
}
.bodyPanelHead {
  width: 100%;
  padding: 16px 16px 0 16px;
  background-color: $black-050;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  margin: 0 auto;
  gap: 8px;

  /* Hide scrollbar */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

:-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.tab {
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 24px;
  background: transparent;
  color: $black-700;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 123.077% */
  letter-spacing: -0.52px;
  min-width: fit-content;
  width: fit-content;
  &:hover,
  &:focus-visible {
    background-color: darken($black-100, 5%);
  }
}

.activeTab {
  background: $black-800;
  border: 1px solid $black-700;
  color: $black-050;
  cursor: default;
  &:hover,
  &:focus-visible {
    background-color: $black-800;
  }
}
.bodyPanelTitle {
  color: $black-300;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 160% */
  letter-spacing: -0.8px;
  margin: 0;
}

.contentBox {
  display: flex;
  flex-direction: column;
  width: 100%;

  margin: auto;
  gap: 8px;
}

.likesArray {
  display: grid;
  columns: 1;
  grid-template-columns: 1fr;
  gap: 8px;
  width: 100%;
  margin: auto;
}

.spinner {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.emptyState {
  color: $black-500;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin: 16px 0;
}

.userDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  max-width: 100%;
}

.entry {
  display: flex;
  flex-direction: column;
  margin: 8px 0;
}
.entryTitle {
  color: $black-500;
  font-family: Inter;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.56px;
  margin: 0;
}
.entryContent {
  margin: 0;
  white-space: pre-wrap;
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.56px;

  a {
    color: $black-700;
    text-decoration: underline;
  }
}
.avatar {
  height: 320px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
  border-radius: 8px;
  border: 1px solid $black-700;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 24px 16px 24px;
  & > h1 {
    margin: 0;

    color: $yellow-300;
    text-shadow:
      -1px -1px 0 $black-700,
      1px -1px 0 $black-700,
      -1px 1px 0 $black-700,
      1px 1px 0 $black-700;
  }
}
