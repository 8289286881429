@import '../styles/global';

:root {
  --plyr-color-main: #fcd34d;
  --plyr-video-control-color: #404040;
  --plyr-video-control-color-hover: #404040;
  --plyr-video-background: #f5f5f5;
  --plyr-video-controls-background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #fff 100%
  );
  --plyr-control-radius: 0px;
  --plyr-captions-text-color: #525252;
  --plyr-badge-text-color: #404040;
  --plyr-control-padding: 0;
}
