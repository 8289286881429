@import '../styles/global';

.songContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid $black-700;
  background: $black-100;
  transition: all 0.025s ease-in-out;
  margin: 0 auto 24px auto;
  box-shadow: 2px 2px 0px 0px $black-700;

  &:hover {
    background: darken($black-100, 1%);
  }
}

.songContainerHeader {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-bottom: 1px solid $black-800;
}

.songContainerBody {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  align-self: stretch;
}

.songPostedDate {
  color: $black-700;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 184.615% */
  letter-spacing: -0.52px;
  margin: 0;
}

.songLinkBody {
  width: 100%;
  padding: 16px;
}

.songLink {
  width: 100%;
}

.songCluster {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $black-300;
  overflow: hidden;

  transition: all 0.025s ease-in-out;
  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    background: $yellow-300;
    border-color: $black-700;
    background-blend-mode: multiply;
    & .thumbnailImage {
      mix-blend-mode: multiply;
    }
  }
}

.songInfo {
  display: flex;
  flex-direction: column;
  gap: 0;
  margin: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.songName {
  color: $black-700;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.64px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.songArtist {
  color: $black-700;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 184.615% */
  letter-spacing: -0.52px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.thumbnailImage {
  display: flex;
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  background-position: center;
  background-size: cover;
}

.songFooter {
  width: 100%;
}

.songCommentBox {
  width: 100%;
  padding: 40px 16px 32px 16px;
  justify-content: left;
  align-items: top;
  gap: 8px;
  color: $black-700;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.64px;
  overflow: hidden;
  position: relative;
  .descriptionBoxLabel {
    position: absolute;
    top: 16px;
    left: 16px;
    font-size: 13px;
    line-height: 16px;
    color: $black-500;
  }
}

.songComment {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap; /* Preserve whitespace formatting */
}

.utilButtons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  margin: 0;
  width: 100%;
  padding: 16px;
  border-top: 1px solid $black-700;
}

.deleteOption {
  color: #ff0000;
  &:hover {
    color: darken(#ff0000, 10%);
  }
}
