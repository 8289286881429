@import '../styles/global';

.loggedOutContainer {
  width: 100%;
  max-width: 100vw;
  background-color: transparent;
  padding: 8px;
  z-index: 888;
  position: fixed;
  bottom: 0px;
  left: 0;
  background-color: $black-050;
  border-top: 1px solid $black-700;
}

.container {
  display: flex;
  width: 100%;
  padding: 8px 32px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $black-800;
  background: $black-050;
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 888;
}

.navLink {
  height: 32px;
  width: 32px;
  background-color: $black-050;
  border: none;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 28px;
    height: 28px;
  }
}

.bottomBarAvatar {
  width: 32px;
  height: 32px;
  border-radius: 24px;
  border: 1px solid $black-700;
}

.postCTA {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 64px;
  background-color: $yellow-400;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.postedCTA {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 64px;
  background-color: $black-200;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
