@import '../../styles/global';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(253, 224, 71, 0.8);
  z-index: 2000;
}

.modal {
  position: relative;
  top: 50%;
  left: 50%;
  width: calc(100vw - 32px);
  height: calc(100vh - 32px);
  transform: translate(-50%, -50%);
  background: $black-100;
  z-index: 2001;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  border: 1px solid $black-700;
  box-shadow: 2px 2px 0 0 $black-700;
  overflow: hidden;

  @include for-phone-only {
    width: 100%;
    height: 100%;
  }
}

.modalSmall {
  position: relative;
  top: 50%;
  left: 50%;
  width: 400px;
  height: calc(100vh - 256px);
  transform: translate(-50%, -50%);
  background: $black-050;
  padding: 0;
  z-index: 1000;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  @include for-phone-only {
    width: 100%;
    height: 100%;
  }
}

.header {
  width: 100%;
  height: 56px;
  background-color: $black-050;
  display: flex;
  flex-direction: row;
  padding: 16px;
  justify-content: space-between;
  border-bottom: 1px solid $black-700;
  align-items: center;
}

.title {
  margin: 0;
  color: $black-700;
}

.closeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  background: transparent;
  border-radius: 24px;
  padding: 4px;
  border: none;
  z-index: 100;
  cursor: pointer;
  &:hover {
    background: $black-200;
  }
}

.body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
  margin: auto;
  width: 100%;
  overflow-y: scroll;
  @include for-phone-only {
    padding-top: 8px;

    margin: 0;
  }
}
